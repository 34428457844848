<template>
  <div class="order-list-supplier">
    <v-app>
      <v-container fluid>
        <v-card class="pt-8 mt-6">
          <v-app-bar class="navbar" app height="50px">
            <div class="header-title">
              <div class="d-flex align-center">
                <h1>รายการคำสั่งซื้อ</h1>
              </div>
            </div>
          </v-app-bar>

          <v-container fluid>
            <div
              v-if="noData === true"
              class="d-flex flex-column align-center"
            >
              <v-icon color="red" x-large>mdi-clipboard-text</v-icon>
              <span style="color: red">ไม่มีรายการสั่งซื้อ</span>
            </div>
            <vue-element-loading
              :active="isLoadPr"
              spinner="line-scale"
              color="#EF4035"
              class="my-5"
            />
            <v-card class="mb-2" v-for="(data, i) in allOrder" :key="i">
              <div class="container" fluid>
                <div class="card-order-list">
                  <div>
                    <h4>
                      หมายเลขคำสั่งซื้อ : {{ data.purchaseRequisitionNo }}
                    </h4>
                    <h4>
                      สถานะการสั่งซื้อ :
                      <span style="color: #0288d1" class="status-order-list">{{
                        data.purchaseRequisitionStatus
                      }}</span>
                    </h4>
                    <h5 class="font-weight-regular">
                      วันที่สั่งซื้อ : {{ data.createDate | dateFormat }}
                    </h5>
                  </div>
                  <v-divider class="mb-2 mt-2"></v-divider>

                  <div class="d-flex justify-space-between align-center mb-0">
                    <h4>{{ data.goods.length }} รายการ</h4>
                    <h4>
                      รวมการสั่งซื้อ : ฿ {{ data.totalAmountVat | currency2 }}
                    </h4>
                  </div>
                  <v-divider class="mt-2 mb-2"></v-divider>
                  <div class="d-flex justify-space-between">
                    <v-btn
                      class="white--text"
                      small
                      color="red"
                      @click.prevent="setOrderDetails(data)"
                      @click="$router.push('/order-detail')"
                    >
                      รายการ
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-card>
          </v-container>
        </v-card>
      </v-container>
      <Footer />
    </v-app>
  </div>
</template>

<script>
import OrderSupplierService from "@/services/orderSupplier.service";
import moment from "moment";
import liff from "@line/liff";
export default {
  name: "Order-list-supplier",
  data() {
    return {
      allOrder: [],
      isLoadPr: false,
      noData: false,
    };
  },
  async created() {
      await liff.init(
        { liffId: "1656130154-1WM0Y8Aa" },
        () => {
          if (!liff.isLoggedIn()) {
            liff.login();
          } else {
            this.liffGetProfile();
          }
        },
        (err) => console.log(err.code, err.message)
      );
    },
  // created() {
  //   //this.getAllOrderPr();
  //     axios.get(`/orders/pr/suppliers/supplierLine/U4430908ed54610eb1cf99c7e5e6ed026`)
  //       .then(res => {
  //           console.log(res);
  //           this.allOrder = res.data.reverse();
  //       })
  // },
  filters: {
    dateFormat: function (date) {
      return moment(date).format("DD MMMM YYYY, h:mm:ss a.");
    },
  },
  methods: {
    
    liffGetProfile() {
      liff
        .getProfile()
        .then((profile) => {
          this.line_uid = profile.userId;
          // this.line_uid = 'U6a92573bc9f87e722cde4c16d8693240';
          this.getAllOrderPr();
        })
        .catch((err) => console.log(err));
    },
    getAllOrderPr() {
      this.isLoadPr = true
      OrderSupplierService.getOrderSupplierPR(this.line_uid)
        .then((res) => {
          if (res.length == 0) {
            this.isLoadPr = false
            this.noData = true
          } else {
            this.allOrder = res.reverse();
            this.isLoadPr = false
            this.noData = false
          }
            console.log(res);
      });
    },
    setOrderDetails(details) {
      console.log(details);
      this.$store.dispatch("setOrderDetails", details);
    },
  },
};
</script>

<style scoped>
.navbar {
  position: absolute !important;
  width: 95%;
  top: -24px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  background: #2e2253 !important;
  border-radius: 3px !important;
}
.header-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-title h1 {
  color: #fff;
  font-weight: 400;
  font-size: 19px;
}
</style>